<template>
  <div>
    <form method="get" v-bind:action="formroute">
        <div class="calendar field" v-if="!datepickerDisabled">
          <b-datepicker 
          v-model="date" 
          inline
          :loading="true"
          :events="events"
          :min-date="minDate"
          :max-date="maxDate"
          :selectable-dates="selectableCalendarDates"
          :unselectable-dates="unselectableDates"
          :unselectable-days-of-week="weekdays"
          :first-day-of-week="firstDayOfWeek"
          icon-pack="fas"
          @input="dayclick"
          :mobile-native="false"
          :disabled="datepickerDisabled"
          :trap-focus="false"
          />
        </div>
        <div v-if="date">
        <div class="field">
          <div class="control has-icons-left">
            <div class="select is-medium is-fullwidth">
              <select id="available-slots" @change="timeClick($event)">
                <option v-for="(item) in availableTimes" v-bind:key="item.start">
                  {{item}}
                </option>
              </select>
            </div>
            <span class="icon is-small is-left">
              <i class="fas fa-clock"></i>
            </span>
          </div>
        </div>
        <div class="guests" v-if="button['is-loading'] == false">
          <small style="font-size:1.2em;" v-if="this.template === 'event'">
            {{language.seatsleft}} {{selected.max}}
          </small>
          <div class="columns is-mobile">
            <div class="column">
              <div class="field has-addons">
                <p class="control">
                  <a class="button is-static">
                    <span v-if="price_children">{{language.adults}}</span>
                    <span v-else-if="this.template === 'product'">Quantity</span>
                    <span v-else>{{language.guests}}</span>
                  </a>
                </p>
                <p class="control control-number">
                  <input
                    type="number"
                    name="guests_adults"
                    class="input text-center is-narrow"
                    v-model="adult_guests"
                    :min="booking.min"
                    :max="selected.maxAdults"
                    :disabled="booking.disabled"
                    :readonly="booking.readonly"
                  />
                </p>
              </div>
            </div>

            <div class="column" v-if="price_children">
              <div class="field has-addons">
              <p class="control">
                <a class="button is-static">
                  {{language.children}}
                </a>
              </p>
              <p class="control control-number">
                <input
                  type="number"
                  name="guests_children"
                  class="input text-center"
                  v-model="children_guests"
                  min="0"
                  :max="selected.maxChildren"
                  :disabled="booking.disabled"
                  :readonly="booking.readonly"
                />
              </p>
              </div>
            </div>
          </div>
        </div>
        <h3 class="price">
          {{language.price}}
          <span style="float:right">{{formatted_price}}</span>
        </h3>
        <input type="hidden" name="date" :value="selected.timeStamp" />
        <button
          type="submit"
          class="button is-fullwidth is-medium is-marginless"
          v-bind:class="button"
          :disabled="button.disabled"
        >{{button.text}}</button>
        </div>
        <br>
        <span class="notification is-warning" v-if="!datepickerDisabled">{{book_now_info}}</span>
    </form>
    <div v-if="datepickerDisabled" class="has-text-centered">
      <special-reguest-component :product="product" :maxguests="maxguests" :minguests="minguests"></special-reguest-component>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
Vue.component("vue-number-input", VueNumberInput);
var maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() + 1);
export default {
  props: {
    "product": String,
    "template": String,
    "selected_date": String,
    "hour": String,
    "minute": String,
    "cut": String,
    "times": String,
    "default_price": String,
    "excluded":  { type: Array },
    "book_now": String,
    "book_now_info": String,
    "request_special": String,
    "maxguests": String,
    "minguests": String,
    "children": String,
    "adults": String,
    "guests": String,
    "language": { type: Object },
    "formroute": String,
    "csrf": String
  },
  data() {
    return {
      datepickerDisabled: false,
      date: null,
      activeDate: null,
      availableTimes: [],
      activeTime: undefined,
      minDate: new Date(),
      maxDate: new Date(maxDate.getFullYear() + 1, maxDate.getMonth(), maxDate.getDate()),
      events: [],
      selectableDates: [],
      unselectableDates: [],
      weekdays: [],
      firstDayOfWeek: 1,
      adult_guests: 0,
      children_guests: 0,
      selected: {
        guests: 2,
        price: 0,
        max: null,
        maxAdults: 3,
        maxChildren: 1
      },
      booking: {
        adults: 2,
        children: 0,
        min: null,
        disabled: false,
        readonly: false
      },
      price: new Intl.NumberFormat("da-DK", {
        style: "currency",
        currency: "DKK"
      }).format(0),
      price_children: new Intl.NumberFormat("da-DK", {
        style: "currency",
        currency: "DKK"
      }).format(0),
      formatted_price: this.default_price,
      calendar: {
        loading: true
      },
      button: {
        text: this.book_now,
        'button': true,
				'is-success': true,
        'is-loading': false,
        'disabled': true
      }
    }
  },
  watch: {
    adult_guests: function(newGuest, oldGuest) {
      if(newGuest && this.price > 0) {
      this.calculatePrice(this.selected);
      }
    },
    children_guests: function(newGuest, oldGuest) {
      if(newGuest && this.price > 0) {
        this.calculatePrice(this.selected);
      }
    }
  },
  computed: {
    selectableCalendarDates () {
      const dates = this.selectableDates.map(function (item) {
        const d = new Date(item);
        d.setHours(d.getHours() + (d.getTimezoneOffset()/60));
        console.log(d);
        return d;
      });
      return dates;
    }
  },
  methods: {
    dayclick(dayInfo) {
        this.date = dayInfo;
        let obj = this.availableTimes[this.activeTime].trim().split(':');
        this.date.setHours(obj[0], obj[1]);
        this.selected.timeStamp = this.date.getTime();

        this.booking.disabled = true;
        this.button['is-loading'] = true;
        this.fetchPrices();
    },
    timeClick(event){
      let obj = event.target.value.trim().split(':');
      this.date.setHours(obj[0], obj[1]);
      this.selected.timeStamp = this.date.getTime();

      this.activeTime = event.target.selectedIndex;

      this.button['is-loading'] = true;
      this.fetchPrices();
    },
    fetchPrices(param = false) {
      if(param !== false || this.date !== null) {
        let newDate = param ? param : this.date.getFullYear()+'-'+(this.date.getMonth()+1)+'-'+this.date.getDate()+' '+this.date.getHours()+':'+this.date.getMinutes()+':00';
        
        axios.post("/date/" + this.product, { date: newDate }).then(response => {
          this.price =
            response.data.eventDeal && response.data.eventDeal["value"]
              ? response.data.eventDeal["value"]
              : response.data.price;
          this.price_children =
            response.data.eventDeal && response.data.eventDeal["value"]
              ? response.data.eventDeal["value"]
              : response.data.price_children;
          this.selected.max =
            response.data.result[0].guests === undefined
              ? parseInt(response.data.result[0].nofp)
              : parseInt(
                  response.data.result[0].nofp - response.data.result[0].guests
                );
          if(this.booking.min === null) {
            this.booking.min = response.data.min;
            this.adult_guests = this.booking.min;
          }

          if (this.selected.guests > this.selected.max) {
            this.booking.adults = this.selected.max;
            this.formatted_price = this.formatPrice(
              parseFloat(this.selected.price * parseInt(this.adult_guests))
            );
          } else {
            this.formatted_price = this.formatPrice(
              parseFloat(this.selected.price * parseInt(this.booking.adults))
            );
          }
            this.updateQueryString();

          this.selected.timeStamp = response.data.timestamp;
          this.selected.max >= this.booking.min ? false : true;
          this.calendar.loading = false;
          this.button['is-loading'] = false;
          this.button['disabled'] = false;
          this.calculatePrice();
        });
      } else {
        this.datepickerDisabled = true
      }
    },
    formatPrice(amount) {
      return new Intl.NumberFormat("da-DK", {
        style: "currency",
        currency: "DKK"
      }).format(amount);
    },
    calculatePrice() {
      this.booking.adults = this.adult_guests;
      this.booking.children = this.children_guests;
      this.selected.guests =
        parseInt(this.booking.adults) + parseInt(this.booking.children);

      this.selected.maxAdults =
        parseInt(this.selected.max) - parseInt(this.booking.children);
      this.selected.maxChildren =
        parseInt(this.selected.max) - parseInt(this.booking.adults);
      this.selected.price =
        this.price * parseInt(this.booking.adults) +
        this.price_children * parseInt(this.booking.children);
      this.formatted_price = this.formatPrice(parseFloat(this.selected.price));
    },
    makeTimeInterval() {
      var times = [];
      for(var i = 0; i < 24; i++){
        for(var j = 0; j < 4; j++){
          times.push( ('0' + i).slice(-2) + ":" + quarterHours[j] );
        }
      }
    },
    isDateValid(date) {
      for(let i = 0;i < this.selectableDates.length;i++) {
        if(this.selectableDates[i].getTime() == date) {
          return true;
        }
      }
      return false;
    },
    updateQueryString() {
      var url = new URL(window.location.href);
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set('date', this.date.getFullYear()+'-'+(this.date.getMonth()+1)+'-'+this.date.getDate());
      url.search = searchParams;
      url = url.toString();
      window.history.replaceState({url: url}, null, url);
    }
  },
  created() {
    this.button['disabled'] = true;
    this.weekdays = [1,2,3,4,5,6];
    this.unselectableDates = [];
    this.selectableDates = [];

    console.log('selectable', this.selectableDates);

    this.booking.disabled = true;
    this.availableTimes = (this.times) ? this.times.split(',') : [this.hour+':'+this.minute];
    
    axios.get("/product/" + this.product + "/dates", { params: { cut: this.cut, template: this.template } })
    .then(response => {
      this.activeTime = 0;
      for(var i = 0; i < response.data.length; i++) {
        this.selectableDates.push(new Date(response.data[i].date.date.substring(0,10)));
        if(response.data[i].hasGuests) {
          this.events.push({
            date: new Date(response.data[i].date.date.substring(0,10)),
            type: 'is-info'
          });
        }
      }
    })
    .finally(() => {
      if(this.template !== 'product') {
        let enabled = true;
        var date = (this.selected_date) ? new Date(this.selected_date.trim()*1000) : null;
        if(date === null) {
          if(this.selectableDates.length > 0) {
            this.date = this.selectableDates[0];
            this.date.setHours(this.hour,this.minute,0);
          } else {
            enabled = false;
          }
        } else {
          this.date = date;
          if(this.isDateValid(date.valueOf()) === false) {
            this.date = this.selectableDates[0];
            this.date.setHours(this.hour,this.minute,0);
            this.fetchPrices();
            return;
          }
          this.date.setHours(this.hour,this.minute,0);
        }
        this.fetchPrices();
      } else {
        this.button['is-loading'] = false;
      }
    });
  }
};
Vue.component('special-reguest-component', {
  props: ["product","maxguests","minguests"],
  data: function () {
    return {
      request: {
        date: new Date(),
        guests: 2,
        name: null,
        email: null,
        country: null,
        message: null
      },
      offset: this.minguests,
      button: {
        'button': true,
				'is-success': true,
        'is-loading': false,
        'disabled': false
      },
      status: null
    }
  },
  methods: {
    getMax() {
      return parseInt(this.maxguests);
    },
    sendRequest() {
      this.button['disabled'] = true;
      this.button['is-loading'] = true;
      if(this.request.name !== null && this.request.email !== null && this.request.message !== null) {
        axios.post("/product/" + this.product + "/request", { product: this.product, data: this.request }).then(response => {
          if(response.status === 200) {
            this.button['disabled'] = false;
            this.button['is-loading'] = false;
            this.status = response.data.message;
          }
        });
      } else {
        alert('Please review the request form!');
        this.button['disabled'] = false;
        this.button['is-loading'] = false;
      }
    }
  },
  beforeMount() {
    this.request.guests = this.minguests;
  },
  template: `<div id="request-visit" style="text-align:left;"><p>We are unable to find any available dates. Please send us a request and we'll try to find a alternative for you.</p>`
          + `<div style="display:flex;justify-content:space-between;text-align:left;">
              <p style="width:49%;"><label>Date</label><br><input class="input" type="date" v-model="request.date" id="date"></p>
              <p style="width:49%;"><label>Guests</label><br>
              <select class="select is-fullwidth has-text-centered" v-model="request.guests">
                <template v-for="n in 14">
                  <option :value="n" v-bind:selected="n == request.guests">{{ n }}</option>
                </template>
              </select>
              </p>
            </div>
            <div style="display:flex;justify-content:space-between;text-align:left;">
              <p style="width:49%;">Name<br><input class="input" type="text" id="name" v-model="request.name"></p>
              <p style="width:49%;">Email<br><input class="input" type="email" id="email" v-model="request.email"></p>
            </div>
            <p>Message<br><textarea class="textarea" id="message" v-model="request.message"></textarea></p>
            <p>Please be aware that there is a minimum of 18 hours notice.</p>
            <button v-bind:class="button" :disabled="button.disabled" class="button is-success is-fullwidth is-marginless" name="send-request" id="isLoading" @click="sendRequest()">Send request</button>
            <p style="margin-top:20px;text-align:center;" v-if="status !== null">{{status}}</p></div>`
});
</script>